import React from 'react'
import PropTypes from 'prop-types'
import {
    Chart,
    Series,
    ArgumentAxis,
    CommonSeriesSettings,
    ICommonSeriesSettingsProps,
    CommonAxisSettings,
    Grid,
    Export,
    Legend,
    Margin,
    Tooltip,
    Label,
    Format,
    Title,
    Subtitle
  } from 'devextreme-react/chart';


function LineChart(props) {
  return (
    <>
      <Chart
        palette="Violet"
        dataSource={props.datasource}
        // title={`${props.title} Implied Volatility`}
        width={1500}
      >
        <Title text={`${props.title} Implied Volatility`}>
            <Subtitle offset="-15" text={props.subtitle}/>
        </Title>
        <CommonSeriesSettings
          argumentField="label"
          type="spline"
        />
        <CommonAxisSettings>
          <Grid visible={true} />
        </CommonAxisSettings>
        <Series
            valueField='value'
            name="Implied Volatility"
            color="#2ecc71"    
        />
        <Margin bottom={20} />
        <ArgumentAxis
          allowDecimals={false}
          axisDivisionFactor={60}
        >
          <Label>
            <Format type="percentage" />
          </Label>
        </ArgumentAxis>
        <Legend
          verticalAlignment="top"
          horizontalAlignment="right"
        />
        <Export enabled={true} />
        <Tooltip enabled={true} />
      </Chart>    
    </>
  )
}

LineChart.propTypes = {}

export default LineChart
