import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from '../../features/auth/authSlice'

export const BaseURL = process.env.REACT_APP_API_URL
export const BACKEND_PORT = process.env.BACKEND_PORT

const baseQuery = fetchBaseQuery({
  baseUrl: BaseURL,
  credentials: 'same-origin',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.access
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result?.error?.originalStatus === 403) {
    // console.log('sending refresh token')
    // refresh token
    const refreshResult = await baseQuery('token/refresh/', api, extraOptions)
    // console.log(refreshResult)
    if (refreshResult?.data) {
      const user = api.getState().auth.user
      // store new token
      api.dispatch(setCredentials({ ...refreshResult.data, user }))
      // retry api with new token
      result = await baseQuery(args, api, extraOptions)
    } else {
      // console.log('logout')
      api.dispatch(logOut())
    }
  }
  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
})
