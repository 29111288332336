import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'universal-cookie'

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null },
  reducers: {
    setCredentials: (state, action) => {
      const { user, access } = action.payload
      state.user = user
      state.token = access
    },
    logOut: (state, action) => {
      state.user = null
      state.token = null
      let cookies = new Cookies()
      cookies.remove('token', { path: '/' })
      cookies.remove('email', { path: '/' })
    },
  },
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => state.auth.token
