import { useLocation, Navigate, Outlet } from 'react-router-dom'
import Cookies from 'universal-cookie'
import React from 'react'

export const RequireAuth = () => {
  let cookies = new Cookies()
  const token = cookies.get('token')
  const email = cookies.get('email')
  const location = useLocation()
  return token ? <Outlet /> : <Navigate to={'/'} state={{ from: location }} replace />
}
