import { createApi } from './baseApi'
import Cookies from 'universal-cookie'
import Notify from 'devextreme/ui/notify'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'

const cookies = new Cookies()

function showNotify(type, message, refresh_page = true) {
  alert(message)
  Notify(
    {
      message: message,
      height: 55,
      width: 'auto',
      minWidth: 150,
      type: type, // ['error', 'info', 'success', 'warning']
      displayTime: type === 'error' ? 8000 : 2500,
      animation: {
        show: { type: 'fade', duration: 1000, from: 0, to: 1 },
        hide: { type: 'fade', duration: 1000, from: 1, to: 0 },
      },
    },
    {
      position: 'bottom center',
      direction: 'up-push',
    },
  )
  if (type === 'success' && refresh_page) {
    setTimeout(() => {
      window.location.reload()
    }, 2500)
  }
}

export const BaseURL = process.env.REACT_APP_API_URL
export class ProductApiService {
  constructor() {
    this.ApiService = createApi()
  }
  send_reset_password_mail = async (params) => {
    let response = await fetch(
      BaseURL + '/auth_app/user/send_reset_password_mail/',

      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(params),
      },
    )
    let result = await response.json()
    if (response?.status === 200) {
      return true
    }
    showNotify('error', `Send Reset Password Mail Error: ${result['error']}`, false)
    return false
  }  
  reset_password = async (params) => {
    let response = await fetch(
      BaseURL + '/auth_app/user/reset_password/',

      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(params),
      },
    )
    let result = await response.json()
    if (response?.status === 201) {
      showNotify('success', `Password changed successfully`, false)
      window.location.replace("/");
      return true
    }
    showNotify('error', `Reset Password Error: ${result['error']}`, false)
    return false
  }
  create_user = async (params) => {
    let response = await fetch(
      BaseURL + '/auth_app/user/create_user/',

      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(params),
      },
    )
    let result = await response.json()
    if (response?.status === 201) {
      showNotify('success', `User created successfully`, false)
      window.location.replace("/");
      return true
    }
    showNotify('error', `Create User Error: ${result['error']}`, false)
    return false
  }
  get_securityid_list = async () => {
    const token = cookies.get('token') ? cookies.get('token') : null
    let response = await fetch(
      BaseURL + '/api/get-securityid-list/',

      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + token
        },
      },
    )
    let result = await response.json()
    if (response?.status === 200) {
      return result
    }
    showNotify('error', `Get securityid list Error: ${result['error']}`, false)
    return false
  }
  get_historical_impliedVolatilities = async (yyyymmdd,securityid) => {
    const token = cookies.get('token') ? cookies.get('token') : null
    let response = await fetch(
      BaseURL + `/api/get-historical-impliedVolatilities/?YYYYMMDD=${yyyymmdd}&securityid=${securityid}`,

      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: 'Bearer ' + token
        },
      },
    )
    let result = await response.json()
    if (response?.status === 200) {
      return result
    }
    showNotify('error', `Get historical impliedVolatilities Error: ${result['error']}`, false)
    return false
  }  
}
