import { useEffect, useRef, useState } from 'react'
import { SelectBox } from 'devextreme-react/select-box';
import { ProductApiService } from '../../utils/productApiService';
import DateBox from 'devextreme-react/date-box';
import { formatDate_YYYYMMDD, qty_format } from '../../utils/common';
import { Button } from 'devextreme-react/button';
import LineChart from '../../components/Chart';
import { logOut } from '../../features/auth/authSlice';
import { useNavigate, NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'

function Dashboard() {
  const menuicon = useRef()
  const arrow = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch() 
  
  const handleLogout = () => {
    dispatch(logOut({ ...{ access: null }, user: null }))
    navigate('/')
  }

  const [datasource, setDataSource] = useState(null)
  const [selected_security_id, setSelectedSecurityId] = useState(null)
  const [selected_yyyymmdd, setSelectedYYYYMMDD] = useState(null)
  const [impliedVolatilities, setImpliedVolatilities] = useState(null)

  const now = new Date();
  const dateLabel = { 'aria-label': 'Date' };
  const securitylabel = { 'aria-label': 'Security' };

  const showmenu = (e) => {
    let sidebar = document.querySelector(".sidebar");
    sidebar.classList.toggle("close");
  }
  const show_subitems = (e) => {
    let arrowParent = e.target.parentElement.parentElement; //selecting main parent of arrow
    arrowParent.classList.toggle("showMenu");
  }

  const ApiService = new ProductApiService()
  useEffect(() => {
    ApiService.get_securityid_list().then((response) => {
      setDataSource(
        {
          store: {
            type: 'array',
            data: response,
            key: 'SecurityId',
          },
          group: 'groupname',
        }
      )
    })
  }, [])


  const onSelectValueChanged =(e) =>{
    setSelectedSecurityId(e.value)
  }

  const onDateValueChanged =(e) =>{
    setSelectedYYYYMMDD(formatDate_YYYYMMDD(e.value))
  }

  const onSubmit = (e)=>{
    ApiService.get_historical_impliedVolatilities(selected_yyyymmdd,selected_security_id)
    .then((response) => {
      setImpliedVolatilities(response)
    })
  }
  return (
    <>
      <div className="sidebar close">
        <div className="logo-details">
          <i className="bx" ><img style={{ height: "30px" }} src="/assets/images/plusplus.png" alt="" /></i>
          <span className="logo_name">PlusPlus Research</span>
        </div>
        <ul className="nav-links">
          <li>
            <a href="#">
              <i className="bx bx-grid-alt"></i>
              <span className="link_name">Dashboard</span>
            </a>
            <ul className="sub-menu blank">
              <li><a className="link_name" href="#">Dashboard</a></li>
            </ul>
          </li>
          {/* <li>
          <div className="iocn-link">
            <a href="#">
              <i className="bx bx-collection"></i>
              <span className="link_name">Category</span>
            </a>
            <i className="bx bxs-chevron-down arrow" onClick={(e)=>show_subitems(e)}></i>
          </div>
          <ul className="sub-menu">
            <li><a className="link_name" href="#">Category</a></li>
            <li><a href="#">HTML & CSS</a></li>
            <li><a href="#">JavaScript</a></li>
            <li><a href="#">PHP & MySQL</a></li>
          </ul>
        </li>
        <li>
          <div className="iocn-link">
            <a href="#">
              <i className="bx bx-book-alt"></i>
              <span className="link_name">Posts</span>
            </a>
            <i className="bx bxs-chevron-down arrow"></i>
          </div>
          <ul className="sub-menu">
            <li><a className="link_name" href="#">Posts</a></li>
            <li><a href="#">Web Design</a></li>
            <li><a href="#">Login Form</a></li>
            <li><a href="#">Card Design</a></li>
          </ul>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-pie-chart-alt-2"></i>
            <span className="link_name">Analytics</span>
          </a>
          <ul className="sub-menu blank">
            <li><a className="link_name" href="#">Analytics</a></li>
          </ul>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-line-chart"></i>
            <span className="link_name">Chart</span>
          </a>
          <ul className="sub-menu blank">
            <li><a className="link_name" href="#">Chart</a></li>
          </ul>
        </li>
        <li>
          <div className="iocn-link">
            <a href="#">
              <i className="bx bx-plug"></i>
              <span className="link_name">Plugins</span>
            </a>
            <i className="bx bxs-chevron-down arrow"></i>
          </div>
          <ul className="sub-menu">
            <li><a className="link_name" href="#">Plugins</a></li>
            <li><a href="#">UI Face</a></li>
            <li><a href="#">Pigments</a></li>
            <li><a href="#">Box Icons</a></li>
          </ul>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-compass"></i>
            <span className="link_name">Explore</span>
          </a>
          <ul className="sub-menu blank">
            <li><a className="link_name" href="#">Explore</a></li>
          </ul>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-history"></i>
            <span className="link_name">History</span>
          </a>
          <ul className="sub-menu blank">
            <li><a className="link_name" href="#">History</a></li>
          </ul>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-cog"></i>
            <span className="link_name">Setting</span>
          </a>
          <ul className="sub-menu blank">
            <li><a className="link_name" href="#">Setting</a></li>
          </ul>
        </li>
        <li>
          <div className="profile-details">
            <div className="profile-content">
              <img src="image/profile.jpg" alt="profileImg" />
            </div>
            <div className="name-job">
              <div className="profile_name">Prem Shahi</div>
              <div className="job">Web Desginer</div>
            </div>
            <i className="bx bx-log-out"></i>
          </div>
        </li> */}
        </ul>
      </div>
      <section className="home-section">
      <span className="bx home-content" style={{float:"right", marginRight:"20px",cursor:"pointer"}} onClick={(e)=> handleLogout()}>Logout</span>
        <div className="home-content">
          <i className="bx bx-menu" onClick={(e) => showmenu(e)}></i>
          <span className="text" ref={menuicon} >Dashboard</span>
          
        </div>
        <div className="bx dx-fieldset width-750">
        <div className="dx-field">
          <div className="dx-field-label">Instrument</div>
          <div className="dx-field-value">
          <SelectBox
                searchEnabled={true}
                dataSource={datasource}
                valueExpr="SecurityId"
                inputAttr={securitylabel}
                grouped={true}
                onValueChanged={onSelectValueChanged}
                displayExpr="Description" />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Date</div>
          <div className="dx-field-value">
          <DateBox
                width={300}
                defaultValue={now}
                inputAttr={dateLabel}
                type="date"
                onValueChanged={onDateValueChanged}
              />
          </div>
        </div>   
        <div className="dx-field">
          <div className="dx-field-label"></div>
          <div className="dx-field-value">
          <Button
                width={220}
                text="Submit"
                type="default"
                stylingMode="contained"
                onClick={onSubmit}
              />
          </div>
        </div>   
        {impliedVolatilities&&(impliedVolatilities.chart.length === 0 || impliedVolatilities.total.totalCallOpenInt === 0 || impliedVolatilities.total.totalPutOpenInt === 0) &&
        <span className='bx'>No Data</span>
        }                       
        </div>
        <br />
        {impliedVolatilities&&impliedVolatilities.chart.length > 0 && impliedVolatilities.total.totalCallOpenInt > 0 && impliedVolatilities.total.totalPutOpenInt > 0 &&
        <div style={{marginLeft:"20px",marginTop:"50px"}}>
          <LineChart datasource={impliedVolatilities.chart} title={`${impliedVolatilities.total.description} - ${selected_yyyymmdd}`}
          subtitle={`Total Call : ${qty_format(impliedVolatilities.total.totalCallOpenInt)} Total Put: ${qty_format(impliedVolatilities.total.totalPutOpenInt)}`}
          />
        </div>
        }          
      </section>
    </>
  )
}

export default Dashboard