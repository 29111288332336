import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ProductApiService } from '../../utils/productApiService';
import CircularProgress from '@mui/material/CircularProgress';

function ForgotPassword({ open, handleClose }) {
  const [email, SetEmail] = React.useState('')
  const ApiService = new ProductApiService()
  const [loading, SetLoading] = React.useState(false)

  const HandleSendResetPasswordMail = (e) =>{
    
    let data = {email:email}
    SetLoading(true)
    ApiService.send_reset_password_mail(data).then((response) => {
      SetLoading(false)
    })    
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
    //   sx={{
    //     "& .MuiDialog-container": {
    //       "& .MuiPaper-root": {
    //         width: "100%",
    //         maxWidth: "500px",  // Set your width here
    //       },
    //     },
    //   }}
    >
      <DialogTitle>Reset password</DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '90%' }}
      >
        <DialogContentText>
          Enter your account&apos;s email address, and we&apos;ll send you a link to
          reset your password.
        </DialogContentText>
        <OutlinedInput
          autoFocus
          required
          margin="dense"
          id="email"
          name="email"
          label="Email address"
          placeholder="Email address"
          type="email"
          onChange={(e) => SetEmail(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" type="button" onClick={HandleSendResetPasswordMail}>
        <CircularProgress size="30px" style={{ display: loading ? '' : 'none' }} />
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ForgotPassword.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ForgotPassword;